/*
 * Sass variables for use throughout the project
 * @author Andrew Jarombek
 * @since 10/16/2018
 */

$color-default: #aaa;
$color-primary: #96f1ff;
$color-secondary: #87ffdf;
$color-invalid: #ffa496;
$color-warning: #F1FF94;
$color-success: #BBFF94;

$color-dark-default: #ccc;
$color-dark-background: #1e1e1e;
$color-dark-secondary: #2a2a2a;

$width-mobile-small: 500px;
$width-mobile: 700px;
$width-small-desktop: 980px;
$width-large-desktop: 1300px;

$box-shadow: 0 1px 3px rgba(170, 170, 170, 0.5);