/**
 * Sass styling for the Definition component
 * @author Andrew Jarombek
 * @since 5/13/2018
 */

@import "./styles/variables";
@import "./styles/mixins/dyslexie";

.jarombek-def-above {
  height: 40px;
}

.jarombek-def {

  background-color: #eee;
  border-radius: 2px;
  padding: 25px;

  margin-bottom: 20px;
  margin-top: 20px;

  transition: all .4s ease;

  @media (prefers-color-scheme: dark) {
    background: $color-dark-secondary;
  }

  &:hover {
    box-shadow: 0 1px 3px rgba(170, 170, 170, 0.5);
  }

  .jarombek-def-word {
    @include dyslexie;
    text-decoration: underline;
    text-decoration-color: #ddd;

    &:hover {
      &:before {
        visibility: visible;
      }
    }

    &:before {
      content: "#";
      margin-right: 5px;
      font-size: 24px;
      line-height: 1.5;
      visibility: hidden;

      @media screen and (max-width: $width-mobile-small) {
        font-size: 18px;
      }
    }
  }

  .jarombek-def-desc {}
}