/*
 * Sass styling for the TagList component
 * @author Andrew Jarombek
 * @since 10/18/2018
 */

@supports not (grid-area: auto) {
  .jarombek-tag-list {
    display: inline-block;
    margin: 10px auto;
  }
}