/*
 * Sass styling for the StatisticsRankGraph component
 * @author Andrew Jarombek
 * @since 9/19/2021
 */

@import "styles/mixins/sylexiads";
@import "styles/variables";

#jarbek-statistics-rank-graph {
  @include sylexiad;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;

  h4 {
    font-size: 24px;
    padding-bottom: 30px;

    @media (prefers-color-scheme: dark) {
      color: $color-dark-default;
    }
  }

  text#jarombek-line-chart-label {
    @media screen and (max-width: $width-mobile) and (min-width: $width-mobile-small + 1) {
      font-size: 11px;
    }

    @media screen and (max-width: $width-mobile-small) {
      display: none;
    }
  }

  .recharts-wrapper {
    @media screen and (max-width: $width-mobile-small) {
      overflow: hidden;
      left: -20px;
    }
  }

  .recharts-legend-wrapper {
    @media screen and (max-width: $width-mobile-small) {
      overflow: hidden;
      left: 70px !important;
    }
  }
}