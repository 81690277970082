/*
 * Sass styling for the Blog component
 * @author Andrew Jarombek
 * @since 4/8/2018
 */

@import "./styles/variables";

.jarombek-blog {
  padding-top: 100px;
  padding-bottom: 60px;

  display: grid;
  display: -ms-grid;
  grid-template-columns: 100%;
  grid-template-areas: "post"
                       "foot";
  -ms-grid-columns: 100%;
  -ms-grid-rows: auto auto;
}

.jarombek-blog-none {
  justify-self: center;
  -ms-grid-row-align: center;
  height: 100vh;
  padding-top: 50px;
}

.jarombek-blog-footer {
  grid-area: foot;
  -ms-grid-column: 1;
  -ms-grid-row: 2;

  justify-self: center;
  align-self: center;
  -ms-grid-row-align: center;
  -ms-grid-column-align: center;

  text-align: center;

  text-decoration: none;
  color: #333;

  @media (prefers-color-scheme: dark) {
    color: $color-dark-default;
  }

  // IE 9-11
  @media screen and (min-width:0\0) {
    display: block;
  }
}