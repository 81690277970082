/*
 * Sass styling for the Button component
 * @author Andrew Jarombek
 * @since 7/11/2018
 */

@import "./styles/variables";
@import "./styles/mixins/dyslexie";

$header-color: #e4e4e4;
$primary-table-color: #e2eaea;
$secondary-table-color: #edf9fb;
$primary-table-dark-color: adjust-color($color-primary, $alpha: -0.8);
$secondary-table-dark-color: adjust-color($color-secondary, $alpha: -0.8);

.jarombek-comparison-table {}

.jarombek-ct-header-color-default {
  background-color: $header-color;

  @media (prefers-color-scheme: dark) {
    background-color: $color-dark-secondary;
  }
}

.jarombek-ct-title {
  @include dyslexie;
  font-size: 26px !important;
  text-align: center;

  @media screen and (max-width: $width-mobile-small) {
    font-size: 16px !important;
  }
}

.jarombek-ct-body {
  display: flex;
  display: -ms-flexbox;

  flex-direction: row;
  -ms-flex-direction: row;

  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
}

.jarombek-ct-primary-color-default > .jarombek-comparison-table-entry:nth-child(even) {
  background-color: $primary-table-color;

  @media (prefers-color-scheme: dark) {
    background-color: $primary-table-dark-color;
  }
}

.jarombek-ct-secondary-color-default > .jarombek-comparison-table-entry:nth-child(odd) {
  background-color: $secondary-table-color;

  @media (prefers-color-scheme: dark) {
    background-color: $secondary-table-dark-color;
  }
}