/*
 * Sass styling for the ComparisonTableEntry component
 * @author Andrew Jarombek
 * @since 7/11/2018
 */

@import "./styles/variables";

.jarombek-comparison-table-entry {
  flex-grow: 1;
  width: 300px;
  padding-left: 10px;
  padding-right: 10px;
}

.jarombek-ct-entry-content h5 {
  font-size: 26px !important;
  text-align: center;
  padding-top: 5px;

  @media screen and (max-width: $width-mobile-small) {
    font-size: 22px !important;
  }
}

.jarombek-ct-entry-content h5 > code {
  font-size: 26px !important;

  @media screen and (max-width: $width-mobile-small) {
    font-size: 22px !important;
  }
}

.jarombek-ct-entry-content p {
  font-size: 20px !important;

  @media screen and (max-width: $width-mobile-small) {
    font-size: 18px !important;
  }
}

.jarombek-ct-entry-content p > code {
  font-size: 16px !important;
  word-wrap: break-word;

  @media screen and (max-width: $width-mobile-small) {
    font-size: 14px !important;
  }
}