/*
 * Sass styling for the PaginationBar component
 * @author Andrew Jarombek
 * @since 8/4/2018
 */

@import "styles/mixins/sylexiads";
@import "./styles/mixins/transition";
@import "./styles/variables";

.jarbek-pagination-bar {
  @include sylexiad-bold;
  font-size: 28px;
  color: #aaa;

  @supports (display: flex) {
    display: flex;

    justify-content: center;
    align-content: center;
  }

  @supports not (display: flex) {
    display: table;
    margin: 0 auto;
  }

  // IE 9-11
  @media screen and (min-width:0\0) {
    display: -ms-flexbox;
    align-items: center;
    justify-content: center;
  }

  .jarbek-pag-current {
    font-size: 42px;
    color: darken($color-secondary, 40%);
  }

  @each $page in 'first', 'first-spread', 'previous-item', 'current',
  'next-item', 'last-spread', 'last' {
    .jarbek-pag-#{$page} {

      @supports (display: flex) {
        width: 45px;
        text-align: center;
        align-self: center;
        justify-self: center;
      }

      @supports not (display: flex) {
        display: table-cell;
        vertical-align: middle;
        width: 45px;
      }

      // IE 9-11
      @media screen and (min-width:0\0) {
        width: 45px;
        text-align: center;
      }
    }
  }

  @each $page in 'first', 'previous-item', 'next-item', 'last' {
    .jarbek-pag-#{$page} {
      @include transition(text-decoration, underline);
      @include transition(text-decoration-color, #ccc);
      cursor: pointer;
      text-decoration: none;
      color: darken($color-primary, 40%);

      p {
        color: #aaa;
        @include transition(color, darken($color-primary, 40%));
      }
    }
  }
}