/**
 * Sass styling for the Home component
 * @author Andrew Jarombek
 * @since 3/22/2018
 */

@import "./styles/variables";
@import "styles/mixins/sylexiads";
@import "./styles/mixins/transition";

body {
  background-color: white;

  @media (prefers-color-scheme: dark) {
    background-color: $color-dark-background;
  }
}

.jarbek-home {
  width: 100%;
  height: 98vh;
  min-height: 650px;

  background: url("https://asset.jarombek.com/jarombek-home-background.jpg")
                no-repeat center center fixed;
  background-size: cover;

  @media screen and (max-width: $width-mobile) and (min-width: $width-mobile-small + 1) {
    background: url("https://asset.jarombek.com/jarombek-home-background.jpg")
                no-repeat center center;
    background-size: cover;
    height: 85vh;
  }

  @media screen and (max-width: $width-mobile-small) {
    background: url("https://asset.jarombek.com/jarombek-home-background.jpg")
                no-repeat center center;
    background-size: cover;
    height: 80vh;
    min-height: 550px;
  }
}

.jarbek-home-main {
  height: 98vh;
  min-height: 650px;
  background: rgba(0, 0, 0, 0.65);

  @media screen and (max-width: $width-mobile) and (min-width: $width-mobile-small + 1) {
    height: 85vh;
  }

  @media screen and (max-width: $width-mobile-small) {
    height: 70vh;
    min-height: 550px;
  }
}

.jarbek-home-main-overlay {
  height: 100%;
  position: relative;
}

.jarbek-home-title-container {
  padding-top: 150px;
  text-align: center;

  @media screen and (max-width: $width-mobile-small) {
    padding-top: 80px;
  }
}

.jarbek-home-title {
  @include sylexiad-bold;
  color: rgba(255, 255, 255, 0.9);
  font-size: 62px;

  @media screen and (max-width: $width-mobile-small) {
    font-size: 48px;
  }
}

.jarbek-home-sub-title {
  @include sylexiad;
  color: rgba(255, 255, 255, 0.9);
  padding-top: 50px;
  text-align: center;
  font-size: 36px;

  @media screen and (max-width: $width-mobile-small) {
    font-size: 28px;
  }
}

.jarbek-home-buttons {
  margin-top: 180px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: $width-mobile-small) {
    margin-top: 100px;
    flex-direction: column;
  }
}

.jarbek-home-blog-button {
  margin-right: 25px;

  @media screen and (max-width: $width-mobile) {
    margin-right: 15px;
  }

  @media screen and (max-width: $width-mobile-small) {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.jarbek-home-polls-button {
  margin-left: 25px;

  @media screen and (max-width: $width-mobile) {
    margin-left: 15px;
  }

  @media screen and (max-width: $width-mobile-small) {
    margin: 0;
  }
}

.jarbek-home-blog-button div {
  width: 100%;
  display: inline-flex;
}

.jarbek-home-blog-button div button {
  margin: 0 auto;
}

.jarbek-home-arrow div {
  bottom: 5px;
  right: calc(50vw - 25px);
  position: absolute;
}

.jarbek-home-break {
  background-color: white;
  height: 280px;

  @media (prefers-color-scheme: dark) {
    background-color: $color-dark-secondary;
  }

  @media screen and (max-width: $width-mobile-small) {
    height: 180px;
  }
}

.jarbek-home-occupation {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lighten($color-primary, 10%);
  height: 180px;

  @media (prefers-color-scheme: dark) {
    background-color: darken($color-primary, 60%);
  }

  & > .jarbek-home-occupation-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      @include sylexiad;
      font-size: 24px;

      @media (prefers-color-scheme: dark) {
        color: $color-dark-default;
      }

      &.jarbek-home-occupation-title {
        display: flex;
        text-align: center;
      }

      strong {
        @include sylexiad-bold;
      }

      @media screen and (max-width: $width-mobile) {
        font-size: 20px;
      }

      @media screen and (max-width: $width-mobile-small) {
        font-size: 16px;
      }
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;

      p:nth-child(2) {
        padding-left: 4px;

        @media screen and (max-width: $width-mobile-small) {
          padding: 0;
        }
      }

      @media screen and (max-width: $width-small-desktop) {
        flex-direction: column;
      }
    }
  }

  @media screen and (max-width: $width-mobile-small) {
    height: 120px;
  }
}

// Use grid-area instead of grid because of Edge using an old CSS Grid spec
@supports not (grid-area: auto) {
  .jarbek-home-break {
    position: relative;
  }
}

.jarbek-home-break p {
  @include sylexiad;
  text-align: center;
  font-size: 32px;
  color: #2a2a2a;

  height: 100%;

  @media (prefers-color-scheme: dark) {
    color: $color-dark-default;
  }

  @media screen and (max-width: $width-mobile-small) {
    font-size: 28px;
  }
}

@supports (grid-area: auto) {
  .jarbek-home-break p {
    display: grid;
    align-content: center;
  }
}

@supports not (grid-area: auto) {
  .jarbek-home-break p {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 45%);
  }
}

// IE 9-11
@media screen and (min-width:0\0) {
  .jarbek-home-break {
    display: -ms-grid;
    -ms-grid-column-align: center;
    -ms-grid-columns: 1fr;
    -ms-grid-row-align: center;
    -ms-grid-rows: 1fr 1fr 1fr 1fr 1fr;

    p {
      -ms-grid-column: 1;
      -ms-grid-row: 3;
    }
  }
}

.jarbek-home-break p strong {
  @include sylexiad-bold;
}

.footer-icon {
  width: 60px;
  margin: 0 auto;
  padding-top: 120px;
  padding-bottom: 30px;

  @media screen and (max-width: $width-mobile-small) {
    padding-top: 60px;
  }
}

.footer-icon img {
  height: 60px;
}

.footer-icon-home img {
  @media (prefers-color-scheme: dark) {
    // Change the image color to white when the user prefers a dark color scheme
    // https://angel-rs.github.io/css-color-filter-generator/
    filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(2%)
    hue-rotate(256deg) brightness(113%) contrast(101%) !important;
  }
}