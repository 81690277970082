/*
 * Sass styling for the Tag component
 * @author Andrew Jarombek
 * @since 4/8/2018
 */

@import "./styles/variables";
@import "styles/mixins/sylexiads";

$neo4j-green: rgba(98, 179, 69, 0.6);
$nodejs-green: rgba(141, 201, 76, 0.6);
$mongodb-green: rgba(98, 172, 82, 0.6);
$vim-green: rgba(69, 153, 53, 0.6);
$bash-green: rgba(162, 253, 146, 0.6);
$cloudformation-green: rgba(117, 157, 59, 0.6);
$cloudwatch-green: rgba(112, 150, 63, 0.6);
$aws-iam-green: rgba(117, 156, 62, 0.6);
$android-green: rgba(46, 223, 132, 0.6);
$selenium-green: rgba(0, 180, 0, 0.6);
$nginx-green: rgba(67, 160, 71, 0.6);
$uwsgi-green: rgba(181, 204, 95, 0.6);
$flux-green: rgba(89, 195, 95, 0.6);
$splunk-green: rgba(101, 166, 30, 0.6);
$bazel-green: rgba(117, 210, 117, 0.6);
$javascript-yellow: rgba(240, 219, 79, 0.6);
$jss-yellow: rgba(240, 219, 79, 0.6);
$ecmascript6-yellow: rgba(247, 223, 30, 0.6);
$babel-yellow: rgba(249, 220, 74, 0.6);
$python-yellow: rgba(249, 239, 180, 0.6);
$travisci-yellow: rgba(242, 238, 174, 0.6);
$apigateway-yellow: rgba(217, 167, 64, 0.6);
$sql-blue: rgba(33, 115, 199, 0.6);
$java-blue: rgba(219, 231, 243, 0.6);
$java8-blue: rgba(244, 247, 249, 0.6);
$c-blue: rgba(168, 185, 204, 0.6);
$typescript-blue: rgba(37, 122, 204, 0.6);
$css-blue: rgba(38, 77, 228, 0.6);
$assembly-blue: rgba(37, 52, 76, 0.6);
$webpack-blue: rgba(157, 186, 232, 0.6);
$react-blue: rgba(97, 218, 250, 0.6);
$groovy-blue: rgba(98, 152, 170, 0.6);
$cpp-blue: rgba(26, 92, 151, 0.6);
$powershell-blue: rgba(89, 150, 240, 0.6);
$docker-blue: rgba(44, 128, 193, 0.6);
$k8s-blue: rgba(50, 109, 230, 0.6);
$eks-blue: rgba(52, 123, 246, 0.6);
$aws-rds-blue: rgba(45, 114, 184, 0.6);
$elasticsearch-blue: rgba(63, 165, 222, 0.6);
$numpy-blue: rgba(102, 132, 224, 0.6);
$pandas-blue: rgba(15, 3, 84, 0.6);
$r-blue: rgba(31, 100, 182, 0.6);
$packer-blue: rgba(27, 174, 254, 0.6);
$jquery-blue: rgba(8, 104, 171, 0.6);
$go-blue: rgba(90, 202, 226, 0.6);
$dynamodb-blue: rgba(27, 115, 187, 0.6);
$less-blue: rgba(33, 70, 116, 0.6);
$airflow-blue: rgba(6, 198, 211, 0.6);
$swiftui-blue: rgba(77, 196, 255, 0.6);
$github-actions-blue: rgba(33, 136, 255, 0.6);
$elk-aqua: rgba(83, 191, 176, 0.6);
$puppeteer-aqua: rgba(13, 204, 152, 0.6);
$php-purple: rgba(118, 122, 179, 0.6);
$jwt-purple: rgba(216, 85, 249, 0.6);
$bootstrap-purple: rgba(85, 39, 76, 0.6);
$terraform-purple: rgba(91, 76, 230, 0.6);
$csharp-purple: rgba(120, 62, 129, 0.6);
$webassembly-purple: rgba(92, 99, 232, 0.6);
$dotnetcore-purple: rgba(92, 42, 146, 0.6);
$redux-purple: rgba(118, 74, 189, 0.6);
$eslint-purple: rgba(128, 128, 242, 0.6);
$please-build-purple: rgba(191, 151, 255, 0.6);
$goland-purple: rgba(165, 85, 252, 0.6);
$sass-pink: rgba(213, 130, 172, 0.6);
$graphql-pink: rgba(229, 53, 171, 0.6);
$kibana-pink: rgba(237, 80, 152, 0.6);
$prettier-pink: rgba(207, 161, 206, 0.6);
$swift-red: rgba(236, 69, 72, 0.6);
$mongoose-red: rgba(157, 56, 56, 0.6);
$gulp-red: rgba(212, 74, 71, 0.6);
$angular-red: rgba(222, 52, 50, 0.6);
$oracle-red: rgba(237, 56, 51, 0.6);
$unicode-red: rgba(255, 229, 229, 0.6);
$aws-cloudfront-red: rgba(224, 82, 67, 0.6);
$aws-s3-red: rgba(224, 82, 67, 0.6);
$aws-sm-red: rgba(214, 50, 46, 0.6);
$efs-red: rgba(214, 50, 46, 0.6);
$sql-server-red: rgba(187, 75, 77, 0.6);
$jest-red: rgba(153, 66, 91, 0.6);
$enzyme-red: rgba(254, 89, 94, 0.6);
$npm-red: rgba(204, 53, 51, 0.6);
$databricks-red: rgba(255, 53, 32, 0.6);
$html-orange: rgba(239, 101, 55, 0.6);
$rabbitmq-orange: rgba(239, 100, 55, 0.6);
$yaml-orange: rgba(239, 145, 62, 0.6);
$aws-orange: rgba(244, 154, 62, 0.6);
$ec2-orange: rgba(242, 133, 59, 0.6);
$awslambda-orange: rgba(241, 124, 58, 0.6);
$aws-vpc-endpoint-orange: rgba(241, 124, 58, 0.6);
$d3-orange: rgba(231, 147, 86, 0.6);
$svg-orange: rgba(238, 174, 90, 0.6);
$mysql-orange: rgba(244, 158, 67, 0.6);
$apache-spark-orange: rgba(227, 89, 21, 0.6);
$jenkins-lightskin: rgba(240, 214, 183, 0.6);
$express-gray: rgba(224, 226, 228, 0.6);
$json-gray: rgba(121, 119, 119, 0.6);
$batch-gray: rgba(206, 215, 220, 0.6);
$haskell-gray: rgba(153, 153, 153, 0.6);
$ios-gray: rgba(111, 115, 117, 0.6);
$cypress-gray: rgba(92, 92, 94, 0.6);
$ansible-black: rgba(27, 26, 25, 0.6);
$flask-black: rgba(37, 37, 37, 0.6);
$default: rgba(204, 204, 204, 0.6);

.jarombek-tag-color-neo4j {
  background-color: $neo4j-green;
}

.jarombek-tag-color-nodejs {
  background-color: $nodejs-green;
}

.jarombek-tag-color-mongodb {
  background-color: lighten($mongodb-green, 10%);
}

.jarombek-tag-color-vim {
  background-color: lighten($vim-green, 10%);
}

.jarombek-tag-color-bash {
  background-color: lighten($bash-green, 10%);
}

.jarombek-tag-color-cloudformation {
  background-color: lighten($cloudformation-green, 10%);
}

.jarombek-tag-color-cloudwatch {
  background-color: lighten($cloudwatch-green, 10%);
}

.jarombek-tag-color-awsiam {
  background-color: lighten($aws-iam-green, 10%);
}

.jarombek-tag-color-android {
  background-color: lighten($android-green, 10%);
}

.jarombek-tag-color-selenium {
  background-color: lighten($selenium-green, 10%);
}

.jarombek-tag-color-nginx {
  background-color: lighten($nginx-green, 10%);
}

.jarombek-tag-color-uwsgi {
  background-color: lighten($uwsgi-green, 10%);
}

.jarombek-tag-color-flux {
  background-color: lighten($flux-green, 10%);
}

.jarombek-tag-color-splunk {
  background-color: lighten($splunk-green, 10%);
}

.jarombek-tag-color-bazel {
  background-color: lighten($bazel-green, 10%);
}

.jarombek-tag-color-javascript {
  background-color: $javascript-yellow;
}

.jarombek-tag-color-jss {
  background-color: $jss-yellow;
}

.jarombek-tag-color-ecmascript6 {
  background-color: $ecmascript6-yellow;
}

.jarombek-tag-color-babel {
  background-color: lighten($babel-yellow, 10%);
}

.jarombek-tag-color-python {
  background-color: $python-yellow;
}

.jarombek-tag-color-travisci {
  background-color: $travisci-yellow;
}

.jarombek-tag-color-apigateway {
  background-color: lighten($apigateway-yellow, 10%);
}

.jarombek-tag-color-sql {
  background-color: $sql-blue;
}

.jarombek-tag-color-webpack {
  background-color: $webpack-blue;
}

.jarombek-tag-color-java {
  background-color: $java-blue;
}

.jarombek-tag-color-java8 {
  background-color: $java8-blue;
}

.jarombek-tag-color-c {
  background-color: lighten($c-blue, 10%);
}

.jarombek-tag-color-typescript {
  background-color: lighten($typescript-blue, 10%);
}

.jarombek-tag-color-css {
  background-color: lighten($css-blue, 10%);
}

.jarombek-tag-color-assembly {
  background-color: lighten($assembly-blue, 10%);
}

.jarombek-tag-color-react {
  background-color: $react-blue;
}

.jarombek-tag-color-groovy {
  background-color: lighten($groovy-blue, 10%);
}

.jarombek-tag-color-cpp {
  background-color: lighten($cpp-blue, 10%);
}

.jarombek-tag-color-powershell {
  background-color: lighten($powershell-blue, 10%);
}

.jarombek-tag-color-docker {
  background-color: lighten($docker-blue, 10%);
}

.jarombek-tag-color-k8s {
  background-color: lighten($k8s-blue, 10%);
}

.jarombek-tag-color-eks {
  background-color: lighten($eks-blue, 10%);
}

.jarombek-tag-color-awsrds {
  background-color: lighten($aws-rds-blue, 10%);
}

.jarombek-tag-color-elasticsearch {
  background-color: lighten($elasticsearch-blue, 10%);
}

.jarombek-tag-color-numpy {
  background-color: $numpy-blue;
}

.jarombek-tag-color-pandas {
  background-color: lighten($pandas-blue, 10%);
}

.jarombek-tag-color-r {
  background-color: lighten($r-blue, 10%);
}

.jarombek-tag-color-packer {
  background-color: lighten($packer-blue, 10%);
}

.jarombek-tag-color-jquery {
  background-color: lighten($jquery-blue, 10%);
}

.jarombek-tag-color-go {
  background-color: lighten($go-blue, 10%);
}

.jarombek-tag-color-dynamodb {
  background-color: lighten($dynamodb-blue, 10%);
}

.jarombek-tag-color-less {
  background-color: lighten($less-blue, 10%);
}

.jarombek-tag-color-airflow {
  background-color: lighten($airflow-blue, 10%);
}

.jarombek-tag-color-swiftui {
  background-color: lighten($swiftui-blue, 10%);
}

.jarombek-tag-color-github-actions {
  background-color: lighten($github-actions-blue, 10%);
}

.jarombek-tag-color-elk {
  background-color: lighten($elk-aqua, 10%);
}

.jarombek-tag-color-puppeteer {
  background-color: lighten($puppeteer-aqua, 10%);
}

.jarombek-tag-color-swift {
  background-color: lighten($swift-red, 10%);
}

.jarombek-tag-color-mongoose {
  background-color: lighten($mongoose-red, 10%);
}

.jarombek-tag-color-gulp {
  background-color: lighten($gulp-red, 10%);
}

.jarombek-tag-color-angular {
  background-color: lighten($angular-red, 10%);
}

.jarombek-tag-color-oracle {
  background-color: lighten($oracle-red, 10%);
}

.jarombek-tag-color-unicode {
  background-color: $unicode-red;
}

.jarombek-tag-color-awscloudfront {
  background-color: $aws-cloudfront-red;
}

.jarombek-tag-color-awss3 {
  background-color: $aws-s3-red;
}

.jarombek-tag-color-awssm {
  background-color: $aws-sm-red;
}

.jarombek-tag-color-efs {
  background-color: $efs-red;
}

.jarombek-tag-color-sqlserver {
  background-color: lighten($sql-server-red, 10%);
}

.jarombek-tag-color-jest {
  background-color: $jest-red;
}

.jarombek-tag-color-enzyme {
  background-color: lighten($enzyme-red, 10%);
}

.jarombek-tag-color-npm {
  background-color: lighten($npm-red, 10%);
}

.jarombek-tag-color-databricks {
  background-color: lighten($databricks-red, 10%);
}

.jarombek-tag-color-html {
  background-color: lighten($html-orange, 10%);
}

.jarombek-tag-color-rabbitmq {
  background-color: lighten($rabbitmq-orange, 10%);
}

.jarombek-tag-color-yaml {
  background-color: lighten($yaml-orange, 10%);
}

.jarombek-tag-color-aws {
  background-color: lighten($aws-orange, 10%);
}

.jarombek-tag-color-ec2 {
  background-color: lighten($ec2-orange, 10%);
}

.jarombek-tag-color-awslambda {
  background-color: lighten($awslambda-orange, 10%);
}

.jarombek-tag-color-awsvpcendpoint {
  background-color: lighten($aws-vpc-endpoint-orange, 10%);
}

.jarombek-tag-color-d3 {
  background-color: lighten($d3-orange, 10%);
}

.jarombek-tag-color-svg {
  background-color: lighten($svg-orange, 10%);
}

.jarombek-tag-color-mysql {
  background-color: lighten($mysql-orange, 10%);
}

.jarombek-tag-color-apache-spark {
  background-color: lighten($apache-spark-orange, 10%);
}

.jarombek-tag-color-jenkins {
  background-color: $jenkins-lightskin;
}

.jarombek-tag-color-php {
  background-color: lighten($php-purple, 10%);
}

.jarombek-tag-color-jwt {
  background-color: lighten($jwt-purple, 10%);
}

.jarombek-tag-color-bootstrap {
  background-color: lighten($bootstrap-purple, 10%);
}

.jarombek-tag-color-terraform {
  background-color: lighten($terraform-purple, 10%);
}

.jarombek-tag-color-csharp {
  background-color: lighten($csharp-purple, 10%);
}

.jarombek-tag-color-webassembly {
  background-color: lighten($webassembly-purple, 10%);
}

.jarombek-tag-color-dotnetcore {
  background-color: lighten($dotnetcore-purple, 10%);
}

.jarombek-tag-color-redux {
  background-color: lighten($redux-purple, 10%);
}

.jarombek-tag-color-eslint {
  background-color: lighten($eslint-purple, 10%);
}

.jarombek-tag-color-please-build {
  background-color: lighten($please-build-purple, 10%);
}

.jarombek-tag-color-goland {
  background-color: lighten($goland-purple, 10%);
}

.jarombek-tag-color-sass {
  background-color: lighten($sass-pink, 10%);
}

.jarombek-tag-color-graphql {
  background-color: lighten($graphql-pink, 10%);
}

.jarombek-tag-color-kibana {
  background-color: lighten($kibana-pink, 10%);
}

.jarombek-tag-color-prettier {
  background-color: lighten($prettier-pink, 10%);
}

.jarombek-tag-color-express {
  background-color: $express-gray;
}

.jarombek-tag-color-json {
  background-color: lighten($json-gray, 10%);
}

.jarombek-tag-color-batch {
  background-color: lighten($batch-gray, 10%);
}

.jarombek-tag-color-haskell {
  background-color: $haskell-gray;
}

.jarombek-tag-color-ios {
  background-color: lighten($ios-gray, 10%);
}

.jarombek-tag-color-cypress {
  background-color: lighten($cypress-gray, 10%);
}

.jarombek-tag-color-ansible {
  background-color: lighten($ansible-black, 10%);
}

.jarombek-tag-color-flask {
  background-color: lighten($flask-black, 10%);
}

.jarombek-tag-color-default {
  background-color: $default;
}

.jarombek-tag {
  display: inline-block;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
}

.jarombek-tag div {

  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(170, 170, 170, 0.1);

  transition: all .4s ease;
}

.jarombek-tag:hover {

  &:hover div {
    box-shadow: 0 1px 3px rgba(170, 170, 170, 0.5);
  }

  &:hover div .jarombek-tag-name {
    color: black;

    @media (prefers-color-scheme: dark) {
      color: white;
    }
  }
}

.jarombek-tag-small div {
  height: 40px;

  @media screen and (max-width: $width-mobile-small) {
    height: 35px;
  }
}

@supports (grid-area: auto) {
  .jarombek-tag-small div {
    display: grid;
  }
}

@supports not (grid-area: auto) {
  .jarombek-tag-small div {
    display: table;

    p {
      display: table-cell;
      vertical-align: middle;
    }
  }
}

// IE 9-11
@media screen and (min-width:0\0) {
  .jarombek-tag-small div {
    display: -ms-flexbox;
  }
}

@supports (grid-area: auto) {
  .jarombek-tag-large div {
    display: grid;

    grid-template-columns: [image] 40px [label] calc(100% - 39px) [end];
    grid-template-rows: [top] 100% [bottom];
  }
}

@supports not (grid-area: auto) {
  .jarombek-tag-large div {
    display: table;
  }
}

// IE 9-11
@media screen and (min-width:0\0) {
  .jarombek-tag-large div {
    display: -ms-grid;

    -ms-grid-columns: 40px calc(100% - 39px);
    -ms-grid-rows: 100%;
  }
}

.jarombek-tag-picture {
  height: 40px;
  max-width: 45px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;

  @media screen and (max-width: $width-mobile) and (min-width: $width-mobile-small + 1) {
    height: 30px;
    max-width: 35px;
  }

  @media screen and (max-width: $width-mobile-small) {
    height: 25px;
    max-width: 30px;
  }
}

@supports (grid-area: auto) {
  .jarombek-tag-picture {
    // Goes in this order: row-start, column-start, row-end, column-end
    grid-area: top / image / bottom / label;

    justify-self: center;
    align-self: center;
  }
}

@supports not (grid-area: auto) {
  .jarombek-tag-picture {
    display: table-cell;
    vertical-align: middle;
  }
}

// IE 9-11
@media screen and (min-width:0\0) {
  .jarombek-tag-picture {
    -ms-grid-column: 1;
    -ms-grid-row: 1;

    -ms-grid-row-align: center;
    -ms-grid-column-align: center;
  }
}

.jarombek-tag-name {
  @include sylexiad;
  color: #333;
  border-left: transparent 2px;

  align-self: center;
  padding-left: 20px;
  padding-right: 20px;

  @media (prefers-color-scheme: dark) {
    color: darken(white, 5%);
  }
}

@supports (grid-area: auto) {
  .jarombek-tag-name {
    justify-self: center;
  }
}

@supports not (grid-area: auto) {
  .jarombek-tag-name {
    display: table-cell;
    vertical-align: middle;
  }
}

// IE 9-11
@media screen and (min-width:0\0) {
  .jarombek-tag-name {
    -ms-grid-row-align: center;
  }
}

.jarombek-tag-small .jarombek-tag-name {
  @media screen and (max-width: $width-mobile-small) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.jarombek-tag-large .jarombek-tag-name {
  @media screen and (max-width: $width-mobile-small) {
    padding-left: 0;
    padding-right: 10px;
  }
}

// Give jarombek-tag-name a grid location if the tag is large (with a picture)
.jarombek-tag-large div p {
  font-size: 24px;

  @media screen and (max-width: $width-mobile) and (min-width: $width-mobile-small + 1) {
    font-size: 18px;
  }

  @media screen and (max-width: $width-mobile-small) {
    font-size: 14px;
  }
}

@supports (grid-area: auto) {
  .jarombek-tag-large div p {
    grid-area: top / label / bottom / end;
  }
}

// IE 9-11
@media screen and (min-width:0\0) {
  .jarombek-tag-large div p {
    -ms-grid-column: 2;
    -ms-grid-row: 1;
  }
}

.jarombek-tag-small div p {
  font-size: 18px;

  @media screen and (max-width: $width-mobile) and (min-width: $width-mobile-small + 1) {
    font-size: 16px;
  }

  @media screen and (max-width: $width-mobile-small) {
    font-size: 14px;
  }
}
