/*
 * Sass styling for the LineChartLabel component
 * @author Andrew Jarombek
 * @since 9/19/2021
 */

@import "styles/mixins/sylexiads";
@import "styles/variables";

#jarombek-line-chart-label {
  &.jarombek-line-chart-label-bold {
    @include sylexiad-bold;
  }

  @media (prefers-color-scheme: dark) {
    fill: $color-dark-default;
  }
}