/**
 * A mixin that simplifies transition generation
 * @param $property - the property of the element that transitions
 * @param $result - the resulting style of the transition
 * @param $time - the time the transition takes to complete (optional)
 */
@mixin transition($property, $result, $time: .3s, $speed: ease) {
  transition: #{$property} $time #{$speed};

  // #{} syntax compiles the contents as a property name or selector
  &:hover {
    #{$property}: $result;
  }
}