/*
 * Styles shared across the application components
 * @author Andrew Jarombek
 * @since 3/22/2018
 */

@import "./styles/variables";

:root {
  @media (prefers-color-scheme: dark) {
    background: $color-dark-background;
  }
}

.jarombek-background {
  background-image: url("assets/blizzard.png");

  @media (prefers-color-scheme: dark) {
    background: $color-dark-background;
  }
}

@supports (grid-area: auto) {}
@supports not (grid-area: auto) {}