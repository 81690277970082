/*
 * Sass styling for the Table component
 * @author Andrew Jarombek
 * @since 9/20/2021
 */

@import "styles/mixins/sylexiads";
@import "styles/variables";

#jarbek-table {
  @include sylexiad;
  margin: 40px auto 0 auto;
  font-size: 20px;

  max-width: 98%;
  overflow: hidden;
  overflow-x: scroll;

  @media screen and (max-width: $width-small-desktop) and (min-width: $width-mobile + 1) {
    font-size: 18px;
  }

  @media screen and (max-width: $width-mobile) and (min-width: $width-mobile-small + 1) {
    font-size: 16px;
  }

  @media screen and (max-width: $width-mobile-small) {
    font-size: 14px;
  }

  table {
    border-spacing: 1px;
    margin: 0 auto;

    tr {
      border-bottom: 1px solid #bbb;

      &:nth-child(odd) {
        background-color: #f3f3f3;

        @media (prefers-color-scheme: dark) {
          background-color: #333;
          color: $color-dark-default;
        }
      }

      &:nth-child(even) {
        background-color: #fff;

        @media (prefers-color-scheme: dark) {
          background-color: #444;
          color: $color-dark-default;
        }
      }

      td:first-child {
        @include sylexiad-bold;
        color: #777;

        @media (prefers-color-scheme: dark) {
          color: #aaa;
        }
      }

      td:last-child {
        @include sylexiad-bold;
      }
    }

    tbody {
      tr {
        &:last-child {
          border-bottom: 2px solid darken($color-secondary, 30%);

          @media (prefers-color-scheme: dark) {
            border-bottom: 2px solid darken($color-secondary, 10%);
          }
        }
      }
    }

    th, td {
      margin: 0;
      padding: 22px 25px;
      text-align: end;

      @media screen and (max-width: $width-large-desktop) and (min-width: $width-small-desktop + 1) {
        padding: 20px 16px;
      }

      @media screen and (max-width: $width-small-desktop) and (min-width: $width-mobile + 1) {
        padding: 18px 14px;
      }

      @media screen and (max-width: $width-mobile) and (min-width: $width-mobile-small + 1) {
        padding: 14px 12px;
      }

      @media screen and (max-width: $width-mobile-small) {
        padding: 8px;
      }
    }

    th {
      @include sylexiad-bold;
      background-color: darken($color-secondary, 35%);
      color: white;

      @media (prefers-color-scheme: dark) {
        background-color: darken($color-secondary, 60%);
      }

      &:first-child {
        border-radius: 5px 0 0 0;
      }

      &:last-child {
        border-radius: 0 5px 0 0;
      }
    }
  }
}