/**
 * A mixin that sets the font to sylexiad
 */
@mixin sylexiad {
  font: {
    family: 'Sylexiad', Helvetica, sans-serif !important;
    weight: normal;
    style: normal;
  }
  letter-spacing: 0.4px;
}

/**
 * A mixin that sets the font to sylexiad bold
 */
@mixin sylexiad-bold {
  font: {
    family: 'Sylexiad-Bold', Helvetica, sans-serif !important;
    weight: bold;
    style: normal;
  }
}