/*
 * Sass styling for the Statistics component
 * @author Andrew Jarombek
 * @since 9/18/2019
 */

@import "./styles/variables";

#jarbek-statistics {
  padding-top: 100px;

  @media screen and (max-width: $width-mobile) and (min-width: $width-mobile-small + 1) {
    padding-top: 90px;
  }

  @media screen and (max-width: $width-mobile-small) {
    padding-top: 80px;
  }
}

#jarbek-statistics .recharts-wrapper {
  tspan {
    @media (prefers-color-scheme: dark) {
      fill: #aaa;
    }
  }

  line {
    @media (prefers-color-scheme: dark) {
      stroke: #444;
    }
  }
}