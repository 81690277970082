/*
 * Sass styling for the TitleImage component
 * @author Andrew Jarombek
 * @since 4/5/2018
 */

@import "./styles/mixins/dyslexie";
@import "./styles/variables";

.jarbek-figure img {
  filter: opacity(40%);
  cursor: pointer;
  transition: all .4s;
}

.jarbek-image-title {
  @include dyslexie;
  text-align: center;
  font-size: 12px;
  margin-top: 8px;
  opacity: 0;
  transition: all .4s ease;

  @media (prefers-color-scheme: dark) {
    color: $color-dark-default;
  }
}

.jarbek-figure {

  &:hover img {
    filter: opacity(90%);
  }

  &:hover + .jarbek-image-title {
    opacity: 0.9;
  }
}