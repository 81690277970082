/*
 * Sass styling for the PictureButton component
 * @author Andrew Jarombek
 * @since 5/4/2018
 */

@import "styles/mixins/sylexiads";
@import "./styles/mixins/dyslexie";
@import "./styles/variables";

.jarbek-subscribe {
  padding: 20px;
  margin: 10px 0 10px 0;
}

.jarbek-subscribe-title {
  @include sylexiad;
  font-size: 28px;
  text-align: center;

  @media screen and (max-width: $width-mobile-small) {
    font-size: 20px;
  }
}

.jarbek-divider {
  padding: 15px;
  margin-bottom: 20px;
}

.jarbek-divider div {
  border-bottom: 1px solid $color-default;
}

.jarbek-subscribe-coming-soon {
  @include sylexiad;
  text-align: center;
  font-style: italic;
}

.jarbek-subscribe-form {
  display: grid;
  display: -ms-grid;

  grid-template-columns: [left] 100% [right];
  grid-template-rows: [email] auto [email-comment] auto [first-name] auto
                      [first-name-comment] auto [last-name] auto [last-name-comment] auto
                      [password] auto [password-comment] auto [button] auto [bottom];
  -ms-grid-columns: 100%;
  -ms-grid-rows: auto auto auto auto auto auto auto auto auto;
}

.jarbek-input {
  width: 500px;
  height: 45px;
  padding: 5px;

  @media screen and (max-width: $width-mobile-small) {
    width: 90vw;
    height: 40px;
  }
}

.jarbek-input input {
  @include dyslexie;

  width: 94%;
  padding-right: 3%;
  padding-left: 3%;
  height: 40px;

  border: 1px solid #ccc;
  border-radius: 2px;
  font-size: 14px;

  @media screen and (max-width: $width-mobile-small) {
    height: 35px;
  }
}

.jarbek-input input:focus {
  outline: none;
  border: 1px solid darken($color-secondary, 40%);
}

.jarbek-input input::placeholder {
  color: #bbb;
}

.jarbek-input-valid, .jarbek-input-invalid {
  @include sylexiad;
}

.jarbek-input-valid {
  color: darken($color-secondary, 30%);
}

.jarbek-input-invalid {
  color: darken($color-invalid, 10%);
}

.jarbek-input-warning {
  border: 1px solid $color-invalid !important;
}

.jarbek-input-comment-active {

  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.jarbek-input-completed {
  grid-area: email / left / button / right;
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 8;

  @include sylexiad;
  color: #999;
  font-size: 20px;
  text-align: center;
}

.jarbek-input input[type="email"] {
  // Goes in this order: row-start, column-start, row-end, column-end
  grid-area: email / left / email-comment / right;
  -ms-grid-column: 1;
  -ms-grid-row: 1;
}

.jarbek-input-email-comment {
  grid-area: email-comment / left / first-name / right;
  -ms-grid-column: 1;
  -ms-grid-row: 2;
}

.jarbek-input input[type="first"] {
  grid-area: first-name / left / first-name-comment / right;
  -ms-grid-column: 1;
  -ms-grid-row: 3;
}

.jarbek-input-first-name-comment {
  grid-area: first-name-comment / left / last-name / right;
  -ms-grid-column: 1;
  -ms-grid-row: 4;
}

.jarbek-input input[type="last"] {
  grid-area: last-name / left / last-name-comment / right;
  -ms-grid-column: 1;
  -ms-grid-row: 5;
}

.jarbek-input-last-name-comment {
  grid-area: last-name-comment / left / password / right;
  -ms-grid-column: 1;
  -ms-grid-row: 6;
}

.jarbek-input input[type="password"] {
  grid-area: password / left / password-comment / right;
  -ms-grid-column: 1;
  -ms-grid-row: 7;
}

.jarbek-input-password-comment {
  grid-area: password-comment / left / button / right;
  -ms-grid-column: 1;
  -ms-grid-row: 8;
}

.jarbek-input-submit {
  grid-area: button / left / bottom / right;
  -ms-grid-column: 1;
  -ms-grid-row: 9;

  justify-self: center;
  align-self: center;
  -ms-grid-row-align: center;
  -ms-grid-column-align: center;

  padding-top: 30px;
}

.jarbek-input-submit button {
  @media screen and (max-width: $width-mobile-small) {
    height: 40px;
    font-size: 18px;
    width: 90vw;
  }
}